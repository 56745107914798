import RequestAdmin from '@/utils/request'
import config from '@/utils/config'
import { getLocalidades, getLocalidad } from '@/utils/localizacion'

const url = `${config.URL_BASE}puntos-iluminacion`

export async function getPosteSelect() {
  const request = new RequestAdmin()
  const postes = await request.getList(url, 'Punto de Ilumuniacion')
  const result = []
  postes.forEach(company => {
    result.push({ value: company.id, text: company.name })
  })
  return result
}

export async function setLocalidadesTicketsPostes(tickets, municipio) {
  const localidades = await getLocalidades(undefined, municipio)
  // eslint-disable-next-line no-restricted-syntax
  for await (const item of tickets) {
    if (item.punto) {
      const filter = localidades.filter(localidad => localidad.id === Number(item.punto.localidad))
      item.punto.obj_localidad = filter.length > 0 ? filter[0] : null
    } else {
      item.punto = {}
      item.punto.obj_localidad = {
        id: '',
        nombre: 'PENDIENTE DE CONFIRMAR',
      }
    }
  }
  return tickets
}

export async function getPostesCompany(company, location) {
  try {
    const request = new RequestAdmin()
    const localidades = await getLocalidades(undefined, company.municipio)

    const list = await request.getList(`${url}/filtro/${company.id}/${location}`, 'Punto de Iluminación')
    // eslint-disable-next-line no-restricted-syntax
    for await (const item of list) {
      const filter = localidades.filter(localidad => localidad.id === Number(item.localidad))
      item.obj_localidad = filter.length > 0 ? filter[0] : null
    }
    return list
  } catch (err) {
    console.error('Error en cargar Punto de Iluminación', err)
    return []
  }
}

export async function getPostesWithMigration(filter, ligthMode = false) {
  try {
    if (ligthMode) {
      // eslint-disable-next-line no-param-reassign
      filter.fields = {
        id: true,
        latitud: true,
        longitud: true,
        ingresoManual: true,
        tecnologiaLampara: true,
        modeloLampara: true,
        imagenes: true,
        detalleInicial: true,
        numeroPoste: true,
        estado: true,
        localidad: true,
      }
    }

    const request = new RequestAdmin()
    return request.getList(`${url}/migrations?filter=${JSON.stringify(filter)}`, 'Punto de Iluminación')
  } catch (err) {
    console.error('Error en cargar Puntos de Iluminación', err)
    return []
  }
}

export async function getPostesWhere(filter) {
  try {
    const request = new RequestAdmin()
    return request.getList(`${url}?filter=${JSON.stringify(filter)}`, 'Punto de Iluminación')
  } catch (err) {
    console.error('Error en cargar Puntos de Iluminación', err)
    return []
  }
}

export async function getPostesLevantamiento(company, location) {
  try {
    const request = new RequestAdmin()
    const localidades = await getLocalidades(undefined, company.municipio)
    const filter = {
      where: {
        company: company.id,
        detalle: { exists: false },
      },
    }

    if (location > 0) {
      filter.where.localidad = location
    }

    const list = await request.getList(`${url}?filter=${JSON.stringify(filter)}`, 'Punto de Iluminación')
    // eslint-disable-next-line no-restricted-syntax
    for await (const item of list) {
      // eslint-disable-next-line no-shadow
      const filter = localidades.filter(localidad => localidad.id === Number(item.localidad))
      item.obj_localidad = filter.length > 0 ? filter[0] : null
    }
    return list
  } catch (err) {
    console.error('Error en cargar Punto de Ilumuniacion', err)
    return []
  }
}

export async function countPostesFilter(filter) {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${url}/count?where=${JSON.stringify(filter)}`)
  } catch (err) {
    console.error('Error en obtener Ampliaciones', err)
    return null
  }
}

export async function getPostesFilter(filter, includeLocalidades = false, listLocalidades = null) {
  try {
    const request = new RequestAdmin()
    const list = await request.executeGet(`${url}?filter=${JSON.stringify(filter)}`)
    if (includeLocalidades) {
      const localidades = listLocalidades || await getLocalidades()
      // eslint-disable-next-line no-restricted-syntax
      for await (const item of list) {
        const filtro = localidades.filter(localidad => localidad.id === Number(item.localidad))
        item.obj_localidad = filtro.length > 0 ? filtro[0] : null
      }
    }
    return list
  } catch (err) {
    console.error('Error en obtener Ampliaciones', err)
    return null
  }
}

export async function getPoste(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    const item = await request.executeGet(`${url}/${id}`)
    item.localidad = Number(item.localidad)
    if (item.modeloLampara) item.modeloLampara = Number(item.modeloLampara)
    item.obj_localidad = await getLocalidad(item.localidad)
    return item
  } catch (err) {
    console.error('Error en cargar Punto de Ilumuniacion', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function updateCreatePoste(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en actualizar Punto de Ilumuniacion', err)
    return null
  }
}
