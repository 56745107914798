import config from '@/utils/config'
import RequestAdmin from '@/utils/request'
import { formatDate } from '@/utils/fechas'

const jornada = [
  {
    id: 1,
    nombre: 'Diurno',
    value: 'diurno',
  },
  {
    id: 2,
    nombre: 'Nocturno',
    value: 'nocturno',
  },
]

export function getListadoTrabajos(trabajos) {
  let trabajo = ''
  trabajos.forEach(job => {
    if (trabajo !== '') trabajo = `${trabajo}, ${job.nombre}`
    else trabajo = job.nombre
  })

  return trabajo.toUpperCase()
}

// eslint-disable-next-line import/prefer-default-export
export function getListadoConservacion(tipo) {
  if (tipo === 1) return jornada
  return []
}

const url = `${config.URL_BASE}conservaciones`

// eslint-disable-next-line import/prefer-default-export
export async function updateCreateConservacion(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar Conservacion', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function findConservaciones(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener Conservaciones', err)
    return null
  }
}

export async function countConservaciones(where) {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${url}/count?where=${JSON.stringify(where)}`)
  } catch (err) {
    console.error('Error en obtener Conservaciones', err)
    return null
  }
}

export async function getConservacion(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Conservacion', err)
    return null
  }
}
export async function generarReporte(conservacion) {
  let html = `
      <!DOCTYPE html>
      <html lang="es">
      <head>
        <meta charset="utf-8">
        <title>REPORTE DE CONSERVACIÓN</title>
        <meta name="factura" content="width=device-width, initial-scale=1">
        <meta charset="utf-8">
      </head>
      <style>
          @page {
            margin-top: 1.1811in;
            margin-bottom: 0.7874in;
            margin-left: 0.7874in;
            margin-right: 0.7874in;
          }
          .headLogos{
              width: 100%;
              margin-bottom: 5px;
              border-collapse: collapse;
          }
          .letrasLogo{
              background-color: #1f88c2;
              color: white;
              font-family: sans-serif;
          }
          .tabla{
              border-collapse: collapse;
              width: 100%;
              font-family: sans-serif;
          }
          .tituloDatos {
              background-color: #1f88c2;
              color: yellow;
              font-family: sans-serif;
              padding: 3px;
              width: 350px;
          }
          .tituloDetalle {
              background-color: #1f88c2;
              color: yellow;
              font-family: sans-serif;
              padding: 3px;
          }
          th{
              border: 1px solid black;
              font-family: sans-serif;
          }
          td{
              font-family: sans-serif;
              padding-right: 15px;
              padding-left: 15px;
          }
          .Imagenes{
              width:100%;
              border-collapse: collapse;
          }
          .table {
              border: 1px solid black;
              border-collapse: collapse;
              width: 100%;
              padding-right: 50px;
          }
          .lineaFirma2 {
              border-top: 1px solid black;
              margin-left: auto;
              margin-right: auto;
              width: 40%;
          }
          .linea {
              position: relative;
              margin-top: 40px;
              width: 100%;
          }
          .texto {
              text-transform: uppercase;
              text-align: center;
              font-family: arial,serif;
          }
          .linea hr {
              border: none;
              border-top: 1px solid black;
              margin-bottom: 15px;
          }
      
          .linea span {
              width: 80%;
              position: absolute;
              top: -15px;
              transform: translate(-50%, -50%);
          }
          .centro {
              display: flex;
          }
      </style>
      <body>
      <table class="headLogos">
        <tr>
          <th width="66.67%" class="letrasLogo"> <center>Reporte de Conservación<br>Dirección de Servicios Públicos<br>Municipalidad de Villa Nueva</center></th>
          <th width="33.33%">
            <img style="max-width: 180px;" src="https://storage.googleapis.com/apvn-files/imagenes/inspecciones/logo-apvn.jpg" alt="logoapvn"/>
          </th>
        </tr>
      </table>
      <table style="border-collapse: collapse">
        <tr>
          <th class="tituloDatos">INFORMACIÓN GENERAL</th>
        </tr>
      </table>
      <table class="table">
        <tr>
          <td style="padding-left: 15px; width: 13%; font-size:10.0pt">NÚMERO DE CONSERVACIÓN</td>
          <td>
            <div class="linea">
              <hr>
              <span style="left: 20%; text-align:center; text-transform: uppercase; font-size:10.0pt">${conservacion.correlativo}</span>
            </div>
          </td>
          <td style="padding-left: 45px; width: 13%; font-size:10.0pt">POSTE DE REFERENCIA</td>
          <td>
            <div class="linea">
              <hr>
              <span style="left: 20%; text-align:center; text-transform: uppercase; font-size:10.0pt">${conservacion.posteReferencia}</span>
            </div>
          </td>
          ${conservacion.grupo ? `
          <td style="padding-left: 15px; width: 13%; font-size:10.0pt">GRUPO</td>
          <td>
            <div class="linea">
              <hr>
              <span style="left: 20%; text-align:center; text-transform: uppercase; font-size:10.0pt">${conservacion.grupo ? conservacion.grupo : ''}</span>
            </div>
          </td>`
    : ''}
        </tr>
        <tr>
          <td style="padding-left: 15px; width: 13%; font-size:10.0pt">ZONA</td>
          <td>
            <div class="linea">
              <hr>
              <span style="left: 20%; text-align:center; text-transform: uppercase; font-size:10.0pt">${conservacion.zona}</span>
            </div>
          </td>
          <td style="padding-left: 45px; width: 13%; font-size:10.0pt">FECHA</td>
          <td>
            <div class="linea">
              <hr>
              <span style="left: 20%; text-align:center; text-transform: uppercase; font-size:10.0pt">${formatDate(conservacion.fechaCreacion, '/')}</span>
            </div>
          </td>
          ${conservacion.categoria ? `
          <td style="padding-left: 15px; width: 13%; font-size:10.0pt">CATEGORÍA</td>
          <td>
            <div class="linea">
              <hr>
              <span style="left: 40%; text-align:center; text-transform: uppercase; font-size:8.0pt">${conservacion.categoria ? conservacion.categoria.nombre : ''}</span>
            </div>
          </td>`
    : ''}
        </tr>
      </table>
      <br>
      <table style="border-collapse: collapse">
        <tr>
          <th class="tituloDatos">TRABAJO(S) REALIZADO(S)</th>
        </tr>
      </table>
      <table class="table">
        <tr>
          <td>
            <div class="linea">
              <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${getListadoTrabajos(conservacion.trabajosRealizados)}</div>
              <hr>
            </div>
          </td>
        </tr>
      </table>
      
      `
  if (conservacion.trabajosRealizados.filter(i => i.id === 2).length > 0) {
    html = ` ${html} 
          <br>
          <table class="table">
            <tr>
              <th class="tituloDetalle" style="width: 34%">CÓDIGO ANTERIOR</th>
              <th class="tituloDetalle" style="width: 33%">CÓDIGO NUEVO</th>
              <th class="tituloDetalle" style="width: 34%">POTENCIA</th>
            </tr>
          </table>
          <table class="table">
          <tr>
            <td>
              <div class="linea">
                <hr>
                <span style="text-align:center; text-transform: uppercase; font-size:10.0pt">${conservacion.trabajosRealizados.filter(i => i.id === 2)[0].trabajo.codigoAnterior}</span>
              </div>
            </td>
            <td>
              <div class="linea">
                <hr>
                <span style="text-align:center; text-transform: uppercase; font-size:10.0pt">${conservacion.trabajosRealizados.filter(i => i.id === 2)[0].trabajo.codigoNuevo}</span>
              </div>
            </td>
            <td>
                <div class="linea">
                  <hr>
                  <span style="text-align:center; text-transform: uppercase; font-size:10.0pt">${conservacion.trabajosRealizados.filter(i => i.id === 2)[0].trabajo.potencia}</span>
                </div>
              </td>
            </tr>
          </table>`
  }

  if (conservacion.trabajosRealizados.filter(i => i.id === 3).length > 0) {
    html = ` ${html} 
        <br>
        <table class="table">
          <tr>
            <th class="tituloDetalle">BRAZO RETIRADO</th>
            <th class="tituloDetalle">BRAZO INSTALADO</th>
          </tr>
        </table>
        <table class="table">
          <tr>
            <td>
              <div class="linea">
                <hr>
                <span style="text-align:center; text-transform: uppercase; font-size:10.0pt">${conservacion.trabajosRealizados.filter(i => i.id === 3)[0].trabajo.brazoRetirado.nombre}</span>
              </div>
            </td>
            <td>
              <div class="linea">
                <hr>
                <span style="text-align:center; text-transform: uppercase; font-size:10.0pt">${conservacion.trabajosRealizados.filter(i => i.id === 3)[0].trabajo.brazoInstalado.nombre}</span>
              </div>
            </td>
          </tr>
        </table>
        `
  }
  if (conservacion.trabajosRealizados.filter(i => i.id === 8).length > 0) {
    html = ` ${html} 
      <br>
      <table class="table">
        <tr>
          <th class="tituloDetalle">Metros de Poda</th>
        </tr>
      </table>
      <table class="table">
        <tr>
          <td>
            <div class="linea">
              <hr>
              <span style="text-align:center; text-transform: uppercase; font-size:10.0pt">${conservacion.trabajosRealizados.filter(i => i.id === 8)[0].trabajo}</span>
            </div>
          </td>
        </tr>
      </table>
     `
  }
  html = ` ${html} 
      <br>
      <table class="table">
        <tr>
          <th class="tituloDetalle">OBSERVACIONES</th>
        </tr>
      </table>
      <table class="table">
        <tr>
          <td>
            <div class="linea">
              <div style="text-align:center; text-transform: uppercase; font-size:10.0pt"><p>${conservacion.observaciones}</p></div>
              <hr>
            </div>
          </td>
        </tr>
      </table>
      <table class="tabla">
        <tr>
          <th class="tituloDatos">ANEXOS</th>
        </tr>
      </table>
      <table class="Imagenes">
        <tr>
          <th class="tituloDatos">Fotografía Panorámica del Antes</th>
          <th class="tituloDatos">Fotografía Panorámica del Después</th>
        </tr>
        <tr>
          <th><img src="${conservacion.seguimiento[0].url}" alt="img_0" width="400" height="400"></th>
          <th><img src="${conservacion.seguimiento[1].url}" alt="img_1" width="400" height="400"></th>
        </tr>
      </table>
      <br>
      <br>
      <br>
      <br>
      <br>
      <div>
        <div class="centro">
          <hr class="lineaFirma2">
        </div>
        <div class="texto">
          <p>${conservacion.usuario.nombre}<br>ALUMBRADO PÚBLICO DE VILLA NUEVA, S.A.</p>
        </div>
      </div>
      </body>
      </html>
  `

  const body = {
    tamaño: 'A2',
    margenes: {
      top: '3cm',
      right: '2cm',
      bottom: '2cm',
      left: '2cm',
    },
    orientation: 'portrait',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
