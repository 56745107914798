const opcionesSiNo = [
  {
    id: 1,
    nombre: 'Si',
    value: 'si',
  },
  {
    id: 2,
    nombre: 'No',
    value: 'no',
  },
]

const opcionesSiNoYa = [...opcionesSiNo]
opcionesSiNoYa.push({
  id: 3,
  nombre: 'Ya',
  value: 'ya',
})

const opcionesTimer = [...opcionesSiNo]
opcionesTimer.push({
  id: 3,
  nombre: 'Fotocelda',
  value: 'fotocelda',
})

const opcionesCandado = [
  {
    id: 1,
    nombre: 'Se Instaló',
    value: 'si',
  },
  {
    id: 2,
    nombre: 'No',
    value: 'no',
  },
]

const opcionesTiposInternet = [
  {
    id: 1,
    nombre: 'Router',
    value: 'router',
  },
  {
    id: 2,
    nombre: 'Fibra',
    value: 'fibra',
  },
]
const opcionesProteccion = [...opcionesSiNo]

// eslint-disable-next-line import/prefer-default-export
export function getOpciones(tipo) {
  if (tipo === 1) return opcionesSiNo
  if (tipo === 2) return opcionesSiNoYa
  if (tipo === 3) return opcionesTimer
  if (tipo === 4) return opcionesCandado
  if (tipo === 5) return opcionesTiposInternet
  if (tipo === 6) return opcionesProteccion
  return []
}
