<template>
  <div>
    <b-overlay
      :show="show"
    >
      <b-row v-if="detail">
        <b-col :cols="isMobile ? 7 : 3">
          <modal-ingreso-conservacion
            :origen-data="'conservacion'"
            :button-text="'Ingresar Conservación'"
            :modal-titulo="'Ingresar Conservación'"
            :placeholder="'Buscar Puntos de Iluminación...'"
            @refrescar-pagina="loadReport"
          />
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Tipo:</label>
            <v-select
              v-model="tipo"
              label="title"
              :options="tipos"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Estado:</label>
            <v-select
              v-model="etapa"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Fecha Inicio:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="example-input"
                v-model="fechaInicio"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaInicio"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Fecha Fin:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="fecha-fin"
                v-model="fechaFin"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaFin"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="1"
        >
          <br>
          <table style="display: flex; justify-self: end">
            <tr style="display: flex; justify-self: end">
              <td style="padding-top: 4px; display: flex; justify-self: end">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-primary
                  class="btn-icon"
                  title="Buscar"
                  variant="gradient-primary"
                  @click="loadReport()"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </td>
              <td style="padding-top: 4px; display: flex; justify-self: end">
                <reporte-general
                  :rows="rowsReporte"
                  :columns="columnsReporte"
                  :titulo-principal="tituloPrincipal"
                  :titulo-secundario="tituloSecundario"
                  :subtitulo="subtitulo"
                  :titulo-descarga="tituloDescarga"
                />
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <br v-if="isMobile">
      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="columnasConservacion || columns"
            :rows="rows"
            style-class="vgt-table condensed"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Buscar',
            }"
            @on-row-dblclick="showDetalle"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'trabajosRealizados'">
                {{ getListadoTrabajos(props.row.trabajosRealizados) }}
              </span>
              <span v-else-if="props.column.field === 'opciones'">
                <b-dropdown
                  v-if="props.row.estado !== 'ANULADA'"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="generarDocumento(props.row.id)">
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>Imprimir Reporte</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.estado !== 'ANULADA'"
                    @click="anularTicket(props.row.id)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span>Anular</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
              <span v-else-if="props.column.field == 'estado'">
                <b-badge :variant="props.row.estado === 'INGRESADA' ? 'light-success' : 'light-danger'">
                  {{ props.row.estado.toUpperCase() }}
                </b-badge>
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Mostrar
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '15', '20']"
                    class="mx-1"
                    @input="value => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> de {{ props.total }} registros </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="value => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
      <div>
        <b-modal
          id="modal-gateway"
          v-model="detalleAplomado"
          title="Detalle de la Conservación"
          size="lg"
          ok-only
          ok-title="Aceptar"
          no-close-on-backdrop
        >
          <detalleConservacion :conservacion="conservacion" />
        </b-modal>
      </div>
      <div>
        <boton-anulacion
          ref="botonAnulacion"
          :ticket="ticketAnular"
          :origen="'CONSERVACION'"
          :falla="ticketAnular"
          @cerrar-dialogo="cerrarDialogo"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCol, BBadge, BDropdown, BDropdownItem, BModal, VBModal, BRow, BButton, BFormGroup,
  BFormInput, BInputGroupAppend, VBTooltip, VBPopover, BInputGroup, BFormDatepicker, BPagination, BFormSelect, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import detalleConservacion from '@/components/mantenimiento/conservacion/detalleConservacion.vue'
import { calcularFecha, obtenerFechaInicioFin, sumarDiasFecha } from '@/utils/fechas'
import {
  findConservaciones,
  getConservacion,
  getListadoTrabajos,
  generarReporte,
} from '@/utils/mantenimiento/conservacion/conservacionUtils'
import modalIngresoConservacion from '@/components/ModaIngresoFormularios/ModalIngreso.vue'
import botonAnulacion from '@/components/mantenimiento/Anulacion/botonAnulacion.vue'
import ReporteGeneral from '@/components/reportes/reporteGeneral.vue'
import {
  getColumns, getRows,
  subtitulo,
  tituloDescarga,
  tituloPrincipal,
  tituloSecundario,
} from '@/utils/reportes/mantenimientoPreventivo/conservacion'
import { isMobile, sortFn } from '@/utils/funciones'

export default {
  components: {
    vSelect,
    ReporteGeneral,
    modalIngresoConservacion,
    BCol,
    BModal,
    BBadge,
    BDropdown,
    detalleConservacion,
    BDropdownItem,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    VueGoodTable,
    BFormDatepicker,
    BInputGroupAppend,
    botonAnulacion,
    BPagination,
    BFormSelect,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    detail: {
      type: Boolean,
      default: true,
    },
    columnasConservacion: {
      type: Array,
      default: null,
    },
    numeroPoste: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
      origenData: 'conservacion',
      tipo: '',
      pageLength: 15,
      etapa: '',
      ticketAnular: {},
      verDialogoAnulacion: false,
      detalleAplomado: false,
      filtroMantenimiento: '',
      fechaInicio: '',
      fechaFin: '',
      conservacion: null,
      usuario: JSON.parse(localStorage.getItem('userData')),
      option: [
        { title: 'Todos', value: 'TODOS' },
        { title: 'Ingresadas', value: 'INGRESADA' },
        { title: 'Anuladas', value: 'ANULADA' },
      ],
      tipos: [
        { title: 'Todos', value: '' },
        { title: 'Diurnas', value: 'diurno' },
        { title: 'Nocturnas', value: 'nocturno' },
      ],
      columns: [
        {
          label: 'Correlativo',
          field: 'correlativo',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
          sortable: true,
          sortFn: this.sortFnCorrelativo,
        },
        {
          label: 'Poste',
          field: 'posteReferencia',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Diurna / Nocturna',
          field: 'tipoFormulario.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Trabajo(s) Realizado(s)',
          field: 'trabajosRealizados',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
          sortFn: this.sortFn,
          sortable: true,
        },
        {
          label: 'Usuario',
          field: 'usuario.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha Creación',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Categoría',
          field: 'categoria.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Opciones',
          field: 'opciones',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      rows: [],
      columnsReporte: [],
      rowsReporte: [],
    }
  },
  computed: {
    tituloPrincipal() {
      return tituloPrincipal
    },
    tituloSecundario() {
      return tituloSecundario
    },
    subtitulo() {
      return subtitulo
    },
    tituloDescarga() {
      return tituloDescarga
    },
    isMobile() {
      return isMobile()
    },
  },
  watch: {
    numeroPoste: {
      immediate: true,
      handler() {
        if (!this.detail) {
          this.loadReport()
        }
      },
    },
  },
  async created() {
    this.rows = []
    const fechas = obtenerFechaInicioFin()
    this.fechaFin = fechas.fechaFin
    this.fechaInicio = fechas.fechaInicio
    await this.loadReport()
    this.show = false
  },
  methods: {
    sortFn,
    sortFnCorrelativo(x, y, col, rowX, rowY) {
      const aValue = this.getSortableCorrelativo(rowX.correlativo)
      const bValue = this.getSortableCorrelativo(rowY.correlativo)

      return aValue.localeCompare(bValue)
    },
    getSortableCorrelativo(correlativo) {
      if (typeof correlativo === 'number') {
        return correlativo.toString()
      } if (typeof correlativo === 'string') {
        return correlativo.replace(/[A-Z]/g, '')
      }

      return '' // Manejar otros casos si es necesario
    },
    cerrarDialogo() {
      this.loadReport()
      this.$refs.botonAnulacion.verDialogoAnulacion = false
    },
    async anularTicket(id) {
      const conservacion = await getConservacion(id)
      if (conservacion) {
        this.ticketAnular = conservacion
        this.$refs.botonAnulacion.verDialogoAnulacion = true
      }
    },
    async generarDocumento(id) {
      try {
        const conservacion = await getConservacion(id)
        const fileResumen = await generarReporte(conservacion)
        if (fileResumen !== null) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = `conservacion_${conservacion.correlativo}.pdf`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
      } catch (error) {
        console.error(error)
      }
    },
    getListadoTrabajos,
    async loadReport() {
      this.show = true
      const fechaInicio = new Date(this.fechaInicio)
      const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 1)
      const filter = {
        where: {
          and: [
            { companyId: this.usuario.idCompany },
            !this.detail ? {} : { fechaCreacion: { gte: fechaInicio.toISOString() } },
            !this.detail ? {} : { fechaCreacion: { lte: fechaFin.toISOString() } },
          ],
        },
        order: ['fechaCreacion DESC'],
      }

      if (this.numeroPoste && this.numeroPoste !== '') {
        filter.where.and.push({ posteReferencia: this.numeroPoste })
      }

      if (this.etapa && this.etapa.value !== '') {
        if (this.etapa.value !== 'TODOS') {
          filter.where.and.push({ estado: this.etapa.value })
        }
      } else {
        filter.where.and.push({ estado: 'INGRESADA' })
      }

      if (this.tipo && this.tipo.value !== '') {
        filter.where.and.push({ 'tipoFormulario.value': this.tipo.value })
      }

      const data = await findConservaciones(filter)
      this.rowsReporte = getRows([...data])
      // eslint-disable-next-line no-restricted-syntax
      for await (const item of data) {
        item.fechaCreacion = calcularFecha(item.fechaCreacion)
        // item.ruta = await getItem(8, item.rutaId)
      }
      // eslint-disable-next-line prefer-destructuring
      if (data.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.ticketAnular = data[0]
      } else {
        this.ticketAnular = {}
      }
      this.show = false
      this.rows = data

      this.columnsReporte = getColumns()

      this.show = false
    },
    async showDetalle(params) {
      const ticket = await getConservacion(params.row.id)
      if (ticket) {
        this.conservacion = ticket
        this.detalleAplomado = true
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
