import RequestAdmin from '@/utils/request'
import config from '@/utils/config'
import { formatDate } from '@/utils/fechas'

const seguimiento = [
  /* {
    id: 1,
    nombre: 'Ninguno',
    value: 'ninguno',
  }, */
  {
    id: 1,
    nombre: 'Ticket Reparación',
    value: 'ticketAPVN',
  },
  {
    id: 2,
    nombre: 'Reportado a EEGSA',
    value: 'reportEegsa',
  },
]

const url = `${config.URL_BASE}fallas-bt`

// eslint-disable-next-line import/prefer-default-export
export async function updateCreateFalla(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar FALLA', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function findFallas(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener Tickets', err)
    return null
  }
}

export async function countFallas(where) {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${url}/count?where=${JSON.stringify(where)}`)
  } catch (err) {
    console.error('Error en obtener Tickets', err)
    return null
  }
}

export async function getFalla(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Ticket', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export function getListadoFallas(tipo) {
  if (tipo === 2) return seguimiento
  return []
}

function getClassRotate(item) {
  if (item.isMobile) return 'rotate_image'
  return 'no_rotate_image'
}

// eslint-disable-next-line import/prefer-default-export
export async function generarReporte(falla) {
  const html = `
    <!DOCTYPE html>
    <html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="factura" content="width=device-width, initial-scale=1">
  <title>REPORTE DE FALLAS LINEAS BT</title>
  <meta charset="utf-8">
</head>
<style>
    @page {
        margin-top: 1.1811in;
        margin-bottom: 0.7874in;
        margin-left: 0.7874in;
        margin-right: 0.7874in;
      }
    .headLogos{
        width: 100%;
        margin-bottom: 5px;
        border-collapse: collapse;
    }
    .letrasLogo{
        background-color: #1f88c2;
        color: white;
        font-family: sans-serif;
    }
    .tabla{
        border-collapse: collapse;
        width: 100%;
        font-family: sans-serif;
    }
    .tituloDatos {
        background-color: #1f88c2;
        color: yellow;
        font-family: sans-serif;
        padding: 3px;
        width: 300px;
    }
    .tituloDetalle {
        background-color: #1f88c2;
        color: yellow;
        font-family: sans-serif;
        padding: 3px;
    }
    th{
        border: 1px solid black;
        font-family: sans-serif;
    }
    td{
        font-family: sans-serif;
        padding-right: 15px;
        padding-left: 15px;
    }
    .Imagenes{
        width: 100%;
        border-collapse: collapse;
    }
    .table {
        border: 1px solid black;
        border-collapse: collapse;
        width: 100%;
        padding-right: 50px;
    }
    .lineaFirma2 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    .lineaFirma3 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .lineaFirma{
        border-top: 1px solid black;
        width: 25%;
        display: flex;
        margin-top: 150px;
        font-family: arial,serif;
    }
    .linea {
        position: relative;
        margin-top: 40px;
        width: 100%;
    }

    .linea hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .texto {
        text-transform: uppercase;
        text-align: center;
        font-family: arial,serif;
    }
    .centro {
        display: flex;
    }
    .linea span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
</style>
<body>
<table class="headLogos">
  <tr>
    <th width="66.64%" height="20px" class="letrasLogo"> <center>Reporte de Falla de Lineas BT<br>Dirección de Servicios Publicos<br>Municipalidad de Villa Nueva</center></th>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="https://storage.googleapis.com/apvn-files/imagenes/inspecciones/logo-apvn.jpg" alt="logoapvn"/>
    </th>
  </tr>
</table>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">INFORMACIÓN GENERAL</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">NÚMERO DE FALLA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 45%; text-transform: uppercase; font-size:10.0pt">${falla.correlativo}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">POSTE DE REFERENCIA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 40%; text-transform: uppercase; font-size:10.0pt">${falla.posteReferencia}</span>
      </div>
    </td>
  </tr>
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">TIPO DE FALLA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 25%; text-align: center; text-transform: uppercase; font-size:10.0pt;">${falla.tipoFalla.nombre}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">FECHA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 40%; text-transform: uppercase; font-size:10.0pt">${formatDate(falla.fechaCreacion, '/')}</span>
      </div>
    </td>
  </tr>
</table>

<table style="margin-top: 5px" class="table">
  <tr>
    <th style="width: 50%" class="tituloDetalle">LATITUD</th>
    <th style="width: 50%" class="tituloDetalle">LONGITUD</th>
  </tr>
</table>
<table  class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 42%; text-transform: uppercase; font-size:10.0pt">${falla.seguimiento[0].localization.latitude}</span>
      </div>
    </td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 42%; text-align:justify; text-transform: uppercase; font-size:10.0pt">${falla.seguimiento[0].localization.longitude}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">DIRECCIÓN</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
      <div style="text-align:justify; text-transform: uppercase; font-size:10.0pt"><p>${falla.direccion}</p></div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">SOLUCIÓN PROVISIONAL</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
      <div style="text-align:justify; text-transform: uppercase; font-size:10.0pt"><p>${falla.solucionProvisional}</p></div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">DICTAMEN</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <div style="text-align:justify; text-transform: uppercase; font-size:10.0pt">${falla.dictamen}</div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">RECOMENDACIÓN</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td style="max-width: 300px;">
      <div class="linea">
        <div style="text-align:justify; text-transform: uppercase; font-size:10.0pt">${falla.recomendacion}</div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">SEGUIMIENTO</th>
  </tr>
</table>
<table style="width: 100%;" class="table">
  <tr>
    <th style="flex: 1;" class="tituloDatos">TIPO DE SEGUIMIENTO</th>
    <th style="flex: 1;" class="tituloDatos">CORRELATIVO</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 35%; text-align:center; text-transform: uppercase; font-size:10.0pt">${falla.finalizacion.tipo.nombre}</span>
      </div>
    </td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 42%; text-align:center; text-transform: uppercase; font-size:10.0pt">${falla.finalizacion.correlativo}</span>
      </div>
    </td>
  </tr>
</table>
<table style="width: 100%;" class="table">
  <tr>
    <th style="flex: 1;" class="tituloDatos">COMENTARIOS DEL SEGUIMIENTO</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${falla.finalizacion.comentarios}</div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
<table class="Imagenes">
  <tr>
    <th class="tituloDatos">Fotografía Panorámica</th>
    <th class="tituloDatos">Fotografía de Solución</th>
    <th class="tituloDatos">Fotografía de Seguimiento</th>
  </tr>
  <tr>
    <th><img src="${falla.seguimiento[0].url}" alt="img_0" width="400" height="400"></th>
    <th><img src="${falla.seguimiento[1].url}" alt="img_1" width="400" height="400"></th>
    <th><img src="${falla.seguimiento[2].url}" alt="img_2" width="400" height="400"></th>
  </tr>
</table>
<br>
<br>
<br>
<br>
<div>
    <hr class="lineaFirma2">
  </div>
  <div class="texto">
    <p>${falla.usuario.nombre}<br>ALUMBRADO PÚBLICO DE VILLA NUEVA, S.A.</p>
  </div>
</div>
</body>
</html>
  `

  const body = {
    tamaño: 'A2',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '2cm',
      left: '2cm',
    },
    orientation: 'horizontal',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
